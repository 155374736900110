$spacing-half: 0.25rem; // 4px
$spacing-1: 0.5rem; // 8px
$spacing-2: 0.75rem; // 12px
$spacing-3: 1rem; // 16px
$spacing-4: 1.5rem; // 24px
$spacing-5: 2rem; // 32px
$spacing-6: 2.5rem; // 40px
$spacing-7: 3rem; // 48px
$spacing-8: 4rem; // 64px
$spacing-9: 5rem; // 80px
$spacing-10: 8rem; // 128px
$spacing-11: 16rem; // 256px
$spacing-12: 32rem; // 512px

$grid-gutter-mobile: 1.25rem; // 20px
$grid-spacing-mobile: 1.25rem; // 20px
$grid-gutter-tablet: 2.5rem; // 40px
$grid-spacing-tablet: 2.5rem; // 40px
$grid-gutter-desktop: 2.5rem; // 40px
$grid-spacing-desktop: 2.5rem; // 40px
$grid-spacing-desktop-large: 5rem; // 80px

$grid-container-max-width: 1310px; // 1120px + 80px spacing on the sides
