    @import "styles/variables";
    @import "styles/mixins";

.button {
    @include border-radius-m;
    @include text-label;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-2 $spacing-3;
    background: none;
    font-weight: $font-weight-bold;
    text-decoration: none;
    appearance: none;
    box-shadow: none;
    cursor: pointer;
    border: 1px solid transparent;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.loading {
        cursor: wait;
    }
}

.primary {
    background-color: $color-brand-accent;
    color: $color-base-white;

    &:not(:disabled) {
        &:hover,
        &:focus {
            background-color: #bc1620;
        }
    }
}

.secondary {
    background-color: $color-base-white;
    border-color: $color-borders;
    color: $color-base-700;

    &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
            border-color: $color-base-400;
        }

        &:active {
            background-color: $color-base-50;
        }
    }
}

.tertiary {
    background-color: #2b2b2b;
    border-color: #2b2b2b;
    color: $color-base-white;

    &:not(:disabled) {
        &:hover,
        &:focus,
        &:active {
            border-color: #000;
            background-color: #000;
        }
    }
}

.navigation {
    color: $color-brand-accent;
    text-decoration: underline;
    padding: $spacing-1 $spacing-2;
    border-radius: 0;

    &:hover,
    &:active {
        color: $color-brand-accent-hover;
    }
}

.icon {
    width: 1em;
    height: 1em;

    &.loading {
        margin-right: $spacing-1;
        transform-origin: center;
        animation: spin 1s infinite linear;
    }

    &.left {
        margin-right: $spacing-1;
    }

    &.right {
        margin-left: $spacing-1;
    }
}

.iconOnly {
    .icon {
        margin: 0;
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
